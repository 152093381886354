import i18n from '@/locales'
import { api } from '@/main'
import _ from 'lodash'
import router from '@/router'
import {
  AddSectionCommentClient,
  checkApiResponse,
  CompanyAddressItemsClient,
  CompanyAllTagsClient,
  AppFormsClient,
  AppClient,
  CompanyCheckRisksClient,
  CompanyClient,
  CompanyCommissionClient,
  CompanyDepartmentClient,
  CompanyDirectoryItemsClient,
  CompanyEditorEmailClient,
  CompanyInformationClient,
  CompanyInnClient,
  KeyClient,
  CompanyMacroGoalClient,
  CompanyMailingClient,
  CompanyMailingContextClient,
  CompanyOfficesClient,
  CompanyRenameTagClient,
  CompanyRisksClient,
  CompanySectionCommentClient,
  CompanySiteAppClient,
  CompanySiteCookieClient,
  CompanySiteFormsClient,
  CompanySitesClient,
  CompanySubjectsClient,
  CompanySubscriptionsClient,
  CompanyTagsClient,
  CompanyThirdPartiesClient,
  CopyCompanyClient,
  EmployeeClient,
  SiteCookieStatusClient,
  SubscriptionsNotificationClient,
  SubscriptionsClient,
} from '@/api/clients'

export default {
  async init(context, payload = {}) {
    // Инициализация параметров и загрузка начальных данных
    if (!(await context.dispatch('user/check_auth', payload, { root: true }))) {
      context.commit('company/CLEAR_LOADED', null, { root: true })
      return false
    }

    // Если пользователь перешел по публичной ссылке, просто загружаем данные
    if (context.rootState.user.is_public_mode) {
      await context.dispatch('fetchAnonAllCompanyData', payload.route.params.company_id)
      return true
    }

    if (!context.state.companies_loaded) {
      await context.dispatch('fetchCompanies', { company_id: Number(payload.route.params.id) })
    }

    let company_id = 'id' in payload.route.params ? payload.route.params.id : context.getters['last_company_id']
    await context.dispatch('fetchAllCompanyData', company_id)

    return true
  },

  // КОМПАНИИ
  async fetchCompanies(context, payload) {
    // Получает список всех доступных компаний.
    const companyClient = new CompanyClient()
    const result = await companyClient.fetchAll(payload)
    context.commit('SET_COMPANIES', result.data)
  },

  async fetchAllCompanyData(context, company_id) {
    // Получаем все данные компании
    context.commit('CHANGE_COMPANY_LOADED_STATUS', false)

    const companyClient = new CompanyClient()
    const result = await companyClient.init({ company_id: company_id })

    if (!(await checkApiResponse(result, context, false))) {
      return false
    }
    context.commit('SET_COMPANY', result.data.company)
    context.commit('SET_SUBJECTS', result.data.subjects)
    context.commit('SET_THIRD_PARTIES', result.data.third_parties)
    context.commit('SET_COMPANY_TAGS', result.data.tags)
    context.commit(
      'process/SET_PROCESSES',
      { results: result.data.processes, count: result.data.processes.length },
      { root: true },
    )
    context.commit('process/SET_EDITABLE_PROCESSES', result.data.editable_processes, { root: true })
    context.commit('system/SET_EDITABLE_SYSTEMS', result.data.editable_systems, { root: true })

    context.commit('system/SET_SYSTEMS', result.data.systems, { root: true })
    context.commit('SET_COMPANY_PROCESS_SYSTEMS', result.data.process_systems)
    context.commit('SET_PROCESS_SUBJECT_OTHER_ACTIONS', result.data.process_subject_other_actions)
    context.commit('SET_COUNTRIES', result.data.countries)
    context.commit('SET_TECHNOLOGIES', result.data.technologies)

    //Загружаем неактивные процессы
    await context.dispatch('process/fetchInactiveProcesses', { company_id }, { root: true })

    await context.dispatch('fetchInformation', { company_id: company_id })
    await context.dispatch('fetchSiteApp', { company_id: company_id })
    await context.dispatch('fetchCommission', { company_id: company_id })
    await context.dispatch('fetchDepartment', { company_id: company_id })
    await context.dispatch('fetchOffices', { company_id: company_id })
    await context.dispatch('fetchCompanyTags', { company_id: company_id })
    await context.dispatch('fetchSites', { company_id: company_id })
    await context.dispatch('fetchApps', { company_id: company_id })
    await context.dispatch('fetchRisks', { company_id: company_id })
    await context.dispatch('fetchKeys', { company_id: company_id })
    // await context.dispatch('fetchComments', company_id)
    await context.dispatch('fetchSubscriptions', { company_id: company_id })
    await context.dispatch('fetchMailingDate', { company_id: company_id })
    await context.dispatch('fetchMacroGoals', { company_id: company_id })
    await context.dispatch('fetchAddressItems', { company_id: company_id })
    await context.dispatch('fetchMailing', { company_id: company_id })
    await context.dispatch('fetchCompanyThirdPartiesDetails', company_id)
    await context.dispatch('system_server/fetchSystemServers', company_id, { root: true })
    await context.dispatch('workstation/fetchWorkstations', company_id, { root: true })
    await context.dispatch('network_hardware/fetchNetHardwares', company_id, { root: true })
    await context.dispatch('getDirectoryItems', { company_id: company_id })
    await context.dispatch('fetchCriminals', company_id)
    await context.dispatch('fetchSecurityTechnologies', company_id)
    await context.dispatch('fetchThreat', company_id)
    await context.dispatch('fetchThreatAssesments', company_id)
    await context.dispatch('fetchEmployees', { company_id: company_id })
    await context.dispatch('process/fetchDefaultProcesses', { company_id: company_id }, { root: true })
    await context.dispatch('security/getSecurityForms', company_id, { root: true })
    context.commit('CHANGE_COMPANY_LOADED_STATUS', true)
    await context.dispatch('user/getPermissions', { company_id: company_id }, { root: true })
  },

  async fetchAnonAllCompanyData(context, company_id) {
    // Получаем часть данных компании для публичной ссылки
    // await context.dispatch('fetchCompany', { company_id: company_id })

    api.fetch({ url: `/api/v2/companies/${company_id}/init_data/`, company_id }).then(result => {
      // context.commit('process/SET_PROCESSES', result.data.processes, { root: true })
      context.commit('system/SET_SYSTEMS', result.data.systems, { root: true })
      context.commit('SET_SUBJECTS', result.data.subjects)
      context.commit('SET_THIRD_PARTIES', result.data.third_parties)
      context.commit('SET_COMPANY_TAGS', result.data.tags)
      context.commit('SET_COUNTRIES', result.data.countries)
    })

    await context.dispatch('fetchDepartment', { company_id: company_id })
    await context.dispatch('fetchSites', { company_id: company_id })
    await context.dispatch('fetchApps', { company_id: company_id })
    await context.dispatch('fetchRisks', { company_id: company_id })
    await context.dispatch('fetchMacroGoals', { company_id: company_id })
    await context.dispatch('fetchAddressItems', { company_id: company_id })
    await context.dispatch('fetchEmployees', { company_id: company_id })
    await context.dispatch('security/getSecurityForms', company_id, { root: true })
    await context.dispatch(
      'system/fetchSystems',
      { company_id: company_id, offset: 0, limit: context.state.limit_records },
      { root: true },
    )
    await context.dispatch('fetchAllSystems', { company_id: company_id })
    await context.dispatch(
      'process/fetchProcesses',
      { company_id: company_id, offset: 0, limit: context.state.limit_records },
      { root: true },
    )
    await context.dispatch('process/fetchInactiveProcesses', { company_id: company_id }, { root: true })
  },

  async fetchCompany(context, payload) {
    const companyClient = new CompanyClient()
    const result = await companyClient.fetch(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_COMPANY', result.data)
    }
  },

  async openCompany(context, company_id) {
    localStorage.setItem('company', company_id)
    await context.dispatch('fetchAllCompanyData', company_id)
    await router.push({ name: 'forms', params: { id: company_id } })
  },

  async addCompany(context, company) {
    const companyClient = new CompanyClient()
    const result = await companyClient.create(company)
    if ((await checkApiResponse(result, context)) && !result.hasOwnProperty('status')) {
      context.commit('ADD_COMPANY', result.data)
      return true
    } else {
      context.commit('DANGER_TOAST', result.data.name.join(), { root: true })
      return false
    }
  },

  async deleteCompany(context, payload) {
    const companyClient = new CompanyClient()
    const result = await companyClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_COMPANY', payload.id)
    }
  },

  updateCompany: _.debounce(async (context, payload) => {
    let data = {}
    data[payload.field_name] = payload.value
    const companyClient = new CompanyClient()
    const result = await companyClient.update({ ...payload, fields: data })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_COMPANY', result.data)
    }
  }, 500),

  async deleteCompanyDocument(context, payload) {
    let fields = {}
    fields[payload.field_name] = payload.new_uuids
    const companyClient = new CompanyClient()
    const result = await companyClient.update({ ...payload, fields: fields })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_COMPANY', result.data)
      //Удаляем документ, если смогли отвязать
      await context.dispatch('deleteDocument', { uuid: payload.value })
    }
  },

  async copyCompany(context, payload) {
    const copyCompanyClient = new CopyCompanyClient()
    const result = await copyCompanyClient.create(payload)
    if (await checkApiResponse(result, context)) {
      await context.commit('ADD_COMPANY', result.data)
      context.commit('SUCCESS_TOAST', i18n.global.t('company.copy_company'), { root: true })
    }
  },

  // Действия для форм
  async fetchInformation(context, payload) {
    const informationClient = new CompanyInformationClient()
    const result = await informationClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      // Загружаем в store
      context.commit('SET_INFORMATION', result.data)
    }
    // if (result.errors.length) {
    //   context.commit('DANGER_TOAST', i18n.global.t('company.loading_error'), { root: true })
    //   return
    // }
  },

  updateInformation: _.debounce(async (context, payload) => {
    let data = {}
    // Если в payload есть объект data, сохраняем его если нет обновляем поле анкеты информация
    'data' in payload ? (data = { ...payload.data }) : (data[payload.field_name] = payload.value)
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    context.commit('UPDATE_INFORMATION', data)
    // В списке процессов проценты надо обновлять отдельно
    context.commit('UPDATE_PERCENT_INFORMATION', {
      company_id: payload.form.company,
      percent: context.getters.percentInformation,
    })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    data['percent'] = context.getters.percentInformation
    // const result = await api.query('UPDATE', `/companies/${payload.form.company}/information/`, context, data, null)
    const companyInformationClient = new CompanyInformationClient()
    const result = await companyInformationClient.update({
      company_id: payload.form.company,
      fields: data,
    })
    if (!result) {
      await context.dispatch('fetchInformation', { company_id: payload.form.company })
    }
  }, 100),

  async loadCompanyFromApi(context, inn) {
    // const companyInnClient = new CompanyInnClient()
    // const result = await companyInnClient.fetch(inn)
    const result = await api.fetch({ url: `/api/v2/companies/get_company_info/?inn=${inn}` })
    if (result.errors.length) {
      context.commit('DANGER_TOAST', i18n.global.t('company.loading_error'), { root: true })
      return
    }
    return result.data
  },

  async fetchSiteApp(context, payload) {
    const companySiteAppClient = new CompanySiteAppClient()
    const result = await companySiteAppClient.fetchAll(payload)
    if (result.errors) {
      context.commit('DANGER_TOAST', i18n.global.t('company.loading_error'), { root: true })
      return
    }
    if (await checkApiResponse(result, context)) {
      // Загружаем в store
      context.commit('SET_SITE_APP', result.data)
    }
  },

  async fetchAllSiteAppsData({ dispatch }, company_id) {
    // Получаем все данные компании
    await dispatch('fetchApps', { company_id: company_id })
    await dispatch('fetchSites', { company_id: company_id })
    await dispatch('fetchSiteForms', { company_id: company_id })
    await dispatch('fetchAppForms', { company_id: company_id })
  },

  updateSiteApp: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты сайты и приложения
    let data = {}
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    context.commit('UPDATE_SITE_APP', data)
    // В списке процессов проценты надо обновлять отдельно
    context.commit('UPDATE_PERCENT_SITEAPP', { company_id: payload, percent: context.getters.percentSiteApp })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    data['percent'] = context.getters.percentSiteApp
    data[payload.field_name] = payload.value
    const companySiteAppClient = new CompanySiteAppClient()
    const result = await companySiteAppClient.update({
      company_id: payload.company_id,
      fields: data,
    })
    if (!result) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('fetchSiteApp', { company_id: payload.company_id })
    }
  }, 500),

  async fetchCommission(context, payload) {
    const companyCommissionClient = new CompanyCommissionClient()
    const result = await companyCommissionClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_COMMISSION', result.data)
    }
  },

  updateCommission: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты комиссия
    let data = {}
    data[payload.field_name] = payload.value
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    context.commit('UPDATE_COMMISSION', data)
    // В списке процессов проценты надо обновлять отдельно
    context.commit('UPDATE_PERCENT_COMMISSION', {
      company_id: payload.form.company,
      percent: context.getters.percentCommission,
    })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    data['percent'] = context.getters.percentCommission
    // const result = await api.query('UPDATE', `/companies/${payload.form.company}/commission/`, context, data, null)
    const companyCommissionClient = new CompanyCommissionClient()
    const result = await companyCommissionClient.update({
      company_id: payload.form.company,
      fields: data,
    })
    if (!result) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('fetchCommission', { company_id: payload.form.company })
    }
  }, 100),

  async fetchDepartment(context, payload) {
    const companyDepartmentClient = new CompanyDepartmentClient()
    const result = await companyDepartmentClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_DEPARTMENT', result.data)
    }
  },

  updateDepartment: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты
    let data = {}
    data[payload.field_name] = payload.value
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    context.commit('UPDATE_DEPARTMENT', data)
    // В списке процессов проценты надо обновлять отдельно
    context.commit('UPDATE_PERCENT_DEPARTMENT', {
      company_id: payload.form.company,
      percent: context.getters.percentDepartment,
    })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    data['percent'] = context.getters.percentDepartment

    const companyDepartmentClient = new CompanyDepartmentClient()
    const result = await companyDepartmentClient.update({
      company_id: payload.form.company,
      fields: data,
    })
    if (!result) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('fetchDepartment', { company_id: payload.form.company })
    }
  }, 100),

  async fetchSubjects(context, payload) {
    const companySubjectsClient = new CompanySubjectsClient()
    const result = await companySubjectsClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SUBJECTS', result.data)
    }
  },

  async addSubjectGroup(context, payload) {
    // const companySubjectsClient = new CompanySubjectsClient()
    // const result = await companySubjectsClient.create(payload)
    // if (await checkApiResponse(result, context)) {
    //   context.commit('ADD_SUBJECT', result.data)
    // }
    const result = await api.add({
      url: `/api/v2/companies/${payload.company_id}/subjects/`,
      data: payload.fields,
    })
    if ((await checkApiResponse(result, context)) && result.data) {
      context.commit('ADD_SUBJECT', result.data)
      return result.data
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('general.errors.name'), { root: true })
    }
  },

  async updateSubject(context, payload) {
    let data = {}
    data[payload.field_name] = payload.value
    const companySubjectsClient = new CompanySubjectsClient()
    const result = await companySubjectsClient.update({
      company_id: payload.company_id,
      id: payload.id,
      fields: data,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SUBJECT', result.data)
    }
    if (!result.errors.length) {
      await context.dispatch('fetchSubjects', { company_id: payload.company })
    } else {
      context.commit('DANGER_TOAST', 'Не получилось изменить группу, возможно название не уникально', { root: true })
    }
  },

  async deleteCompanySubject(context, payload) {
    const companySubjectsClient = new CompanySubjectsClient()
    const result = await companySubjectsClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SUBJECT', payload.id)
    }
    return !result.errors.length
  },

  async fetchCompanyTags(context, payload) {
    const companyAllTagsClient = new CompanyAllTagsClient()
    const result = await companyAllTagsClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_COMPANY_TAGS', result.data)
    }
  },

  async addTag(context, payload) {
    const companyTagsClient = new CompanyTagsClient()
    const result = await companyTagsClient.create(payload)
    if (await checkApiResponse(result, context)) {
      await context.dispatch('fetchCompanyTags', { company_id: payload.company_id })
      await context.dispatch(
        'process/fetchProcesses',
        { company_id: payload.company_id, offset: 0, limit: context.state.limit_records },
        { root: true },
      )
      await context.dispatch(
        'system/fetchSystems',
        { company_id: payload.company_id, offset: 0, limit: context.state.limit_records },
        { root: true },
      )
    }
  },

  async deleteTag(context, payload) {
    const companyTagsClient = new CompanyTagsClient()
    const result = await companyTagsClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      await context.dispatch('fetchCompanyTags', { company_id: payload.company_id })
      await context.dispatch(
        'process/fetchProcesses',
        { company_id: payload.company_id, offset: 0, limit: context.state.limit_records },
        { root: true },
      )
      await context.dispatch(
        'system/fetchSystems',
        { company_id: payload.company_id, offset: 0, limit: context.state.limit_records },
        { root: true },
      )
    }
  },

  async renameTag(context, payload) {
    try {
      const companyRenameTagClient = new CompanyRenameTagClient()
      const result = await companyRenameTagClient.update({
        company_id: payload.company_id,
        id: payload.id,
        fields: payload,
      })
      if (await checkApiResponse(result, context)) {
        await context.commit('SUCCESS_TOAST', i18n.global.t('forms.tags.success'), { root: true })
        await context.dispatch('fetchCompanyTags', { company_id: payload.company_id })
      }
    } catch (error) {
      context.commit('DANGER_TOAST', i18n.global.t('forms.tags.error'), { root: true })
    }
  },
  //Метод не используется
  // async getProcessSubjects({ commit, rootState }, payload) {
  //   try {
  //     const { data } = await api.client.get(
  //       `${rootState.server}/api/v2/companies/${payload.company}/processes/${payload.process}/subjects/`,
  //     )
  //     commit('SET_PROCESS_SUBJECTS', data)
  //   } catch (error) {
  //     commit('DANGER_TOAST', 'Subjects loading error', { root: true })
  //   }
  // },

  // Методы для работы со списками персональных данных
  async getDirectoryItems(context, payload) {
    const companyDirectoryItemsClient = new CompanyDirectoryItemsClient()
    const result = await companyDirectoryItemsClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_DIRECTORY_ITEMS', result.data)
    }
  },

  async addDirectoryItem(context, payload) {
    // const companyDirectoryItemsClient = new CompanyDirectoryItemsClient()
    // const result = await companyDirectoryItemsClient.create(payload)
    // if (await checkApiResponse(result, context)) {
    //   context.commit('ADD_DIRECTORY_ITEM', result.data)
    // }
    try {
      const result = await api.client.post(
        `${context.rootState.server}/api/v2/companies/${payload.company_id}/directory_items/`,
        payload.fields,
      )
      context.commit('ADD_DIRECTORY_ITEM', result.data)
    } catch (error) {
      context.commit('DANGER_TOAST', i18n.global.t('general.errors.name'), { root: true })
    }
  },

  async deleteDirectoryItem(context, payload) {
    // TODO отловить 400 ошибку и вернуть из класса api.client.js
    // const companyDirectoryItemsClient = new CompanyDirectoryItemsClient()
    // const result = await companyDirectoryItemsClient.delete(payload)
    // if (await checkApiResponse(result, context)) {
    //   context.commit('DELETE_DIRECTORY_ITEM', payload.id)
    // }
    const result = await api.delete({ url: `/api/v2/companies/${payload.company_id}/directory_items/${payload.id}/` })
    if (result.status) {
      context.commit('DELETE_DIRECTORY_ITEM', payload.id)
    } else {
      return result.errors[0].response.data
    }
  },

  async updateDirectoryItem(context, payload) {
    let data = {}
    data[payload.field_name] = payload.value
    const companyDirectoryItemsClient = new CompanyDirectoryItemsClient()
    const result = await companyDirectoryItemsClient.update({
      company_id: payload.company_id,
      id: payload.id,
      fields: data,
    })
    if ((await checkApiResponse(result, context)) && !!result?.data) {
      context.commit('UPDATE_DIRECTORY_ITEM', result.data)
    } else {
      context.commit('DANGER_TOAST', 'Не получилось изменить категорию, возможно название не уникально', { root: true })
    }
  },

  // Методы для работы со списками макро целей
  async fetchMacroGoals(context, payload) {
    const companyMacroGoalClient = new CompanyMacroGoalClient()
    const result = await companyMacroGoalClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_MACRO_GOALS', result.data)
    }
  },

  async addMacroGoal(context, payload) {
    // TODO отловить 400 ошибку и вернуть из класса api.client.js
    // const companyMacroGoalClient = new CompanyMacroGoalClient()
    // const result = await companyMacroGoalClient.create(payload)
    // if (await checkApiResponse(result, context)) {
    //   context.commit('ADD_MACRO_GOAL', result.data)
    // }
    try {
      const result = await api.client.post(
        `${context.rootState.server}/api/v2/companies/${payload.company_id}/macro_goals/`,
        payload.fields,
      )
      context.commit('ADD_MACRO_GOAL', result.data)
    } catch (error) {
      context.commit('DANGER_TOAST', i18n.global.t('general.errors.name'), { root: true })
    }
  },

  async deleteMacroGoal(context, payload) {
    // TODO отловить 400 ошибку и вернуть из класса api.client.js
    // const companyMacroGoalClient = new CompanyMacroGoalClient()
    // const result = await companyMacroGoalClient.delete(payload)
    // if (await checkApiResponse(result, context)) {
    //   context.commit('DELETE_MACRO_GOAL', payload.id)
    // }
    const result = await api.delete({ url: `/api/v2/companies/${payload.company_id}/macro_goals/${payload.id}/` })
    if (result.status) {
      context.commit('DELETE_MACRO_GOAL', payload.id)
    } else {
      return result.errors[0].response.data
    }
  },

  // Key actions
  async fetchKeys(context, payload) {
    const keyClient = new KeyClient()
    const result = await keyClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_KEYS', result.data)
    }
  },

  async fetchKey(context, payload) {
    const keyClient = new KeyClient()
    const result = await keyClient.fetch(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PUBLIC_KEY', result.data)
    }
  },

  async addKey(context, payload) {
    const result = await api.add({ url: `/api/v2/companies/${payload.company}/keys/`, data: payload })
    if (!result.errors.length) {
      context.commit('SET_KEY', result.data)
    }
  },

  async deleteKey(context, payload) {
    const keyClient = new KeyClient()
    const result = await keyClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_KEY', payload.key)
    }
  },

  async updateKey(context, payload) {
    let data = {}
    data[payload.field_name] = payload.value
    const keyClient = new KeyClient()
    const result = await keyClient.update({
      company_id: payload.company_id,
      key: payload.id,
      fields: data,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('SET_KEY', result.data)
    }
  },

  // Offices actions
  async fetchOffices(context, payload) {
    const companyOfficesClient = new CompanyOfficesClient()
    const result = await companyOfficesClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_OFFICES', result.data)
    }
  },

  async addOffice(context, payload) {
    const companyOfficesClient = new CompanyOfficesClient()
    const result = await companyOfficesClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_OFFICE', result.data)
    }
  },

  async deleteOffice(context, payload) {
    const companyOfficesClient = new CompanyOfficesClient()
    const result = await companyOfficesClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_OFFICE', payload.id)
    }
  },

  async updateOffice(context, payload) {
    const companyOfficesClient = new CompanyOfficesClient()
    const result = await companyOfficesClient.update({
      company_id: payload.company,
      id: payload.id,
      fields: payload,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_OFFICES', payload.id)
    }
  },

  // Сотрудники
  async fetchEmployees(context, payload) {
    const fetchEmployees = new EmployeeClient()
    const result = await fetchEmployees.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_COMPANY_EMPLOYEES', result.data)
    }
  },

  async addEmployee(context, payload) {
    // Добавляем сотрудника
    const addEmployeeClient = new EmployeeClient()
    const result = await addEmployeeClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_COMPANY_EMPLOYEE', result.data)
      return result.data
    }
  },

  deleteEmployee: _.debounce(async (context, payload) => {
    const deleteEmployeeClient = new EmployeeClient()
    const result = await deleteEmployeeClient.delete(payload)
    await checkApiResponse(result, context)
  }, 1000),

  updateEmployee: _.debounce(async (context, payload) => {
    // Обновляем поле сотрудника
    let data = {}
    data[payload.field_name] = payload.value
    const updateEmployeeClient = new EmployeeClient()
    const result = await updateEmployeeClient.update({
      ...payload,
      id: payload.id,
      fields: data,
    })
    if (await checkApiResponse(result, context)) {
      await context.commit('UPDATE_COMPANY_EMPLOYEE', result.data)
    }
  }, 500),

  // Третьи лица
  async fetchThirdParties(context, payload) {
    const companyThirdPartiesClient = new CompanyThirdPartiesClient()
    const result = await companyThirdPartiesClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_THIRD_PARTIES', result.data)
    }
  },
  //Метод не используется
  // async fetchThirdParty(context, payload) {
  //   // Получаем систему
  //   await api.query(
  //     'GET',
  //     `/companies/${payload.company}/third_parties/${payload.system_id}/`,
  //     context,
  //     payload,
  //     'SET_SYSTEM',
  //   )
  // },
  // Метод не используется
  // async addThirdParty(context, payload) {
  //   // Добавляем третье лицо
  //   return await api.query(
  //     'CREATE',
  //     `/companies/${payload.company}/third_parties/`,
  //     context,
  //     payload,
  //     'ADD_THIRD_PARTY',
  //   )
  // },
  // Метод не используется
  // async deleteThirdParty(context, payload) {
  //   // Удаляет процесс
  //   await api.query(
  //     'DELETE',
  //     `/companies/${payload.company}/third_parties/${payload.id}/`,
  //     context,
  //     payload,
  //     'DELETE_THIRD_PARTY',
  //   )
  // },

  updateThirdParty: _.debounce(async (context, payload) => {
    // Обновляем поле третьего лица
    let data = {}
    data[payload.field_name] = payload.value
    const companyThirdPartiesClient = new CompanyThirdPartiesClient()
    const result = await companyThirdPartiesClient.update({
      company_id: payload.form.company,
      id: payload.form.id,
      fields: data,
    })
    if (await checkApiResponse(result, context)) {
      // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
      context.commit('UPDATE_THIRD_PARTY', { ...data, id: payload.form.id })
    }
  }, 1000),

  async updateThirdPartyFields(context, payload) {
    const companyThirdPartiesClient = new CompanyThirdPartiesClient()
    const result = await companyThirdPartiesClient.update({
      company_id: payload.form.company,
      id: payload.form.id,
      fields: payload.fields,
    })
    if (!result) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('fetchThirdParty', { company: payload.form.company, third_party_id: payload.form.id })
    } else {
      //Обновляем поля в store
      context.commit('UPDATE_THIRD_PARTY', { ...payload.fields, id: payload.form.id })
    }
  },

  // Метод не используется
  // async addThirdPartySubject(context, payload) {
  //   // Добавляем третье лицо
  //   return await api.query(
  //     'CREATE',
  //     `/companies/${payload.company}/third_party_subjects/`,
  //     context,
  //     payload,
  //     'ADD_THIRD_PARTY_SUBJECT',
  //   )
  // },
  // Метод не используется
  // async deleteThirdPartySubject(context, payload) {
  //   // Удаляет процесс
  //   await api.query(
  //     'DELETE',
  //     `/companies/${payload.company}/third_party_subjects/${payload.id}/`,
  //     context,
  //     payload,
  //     'DELETE_THIRD_PARTY_SUBJECT',
  //   )
  // },
  // Метод не используется
  // updateThirdPartySubject: _.debounce(async (context, payload) => {
  //   // Обновляем поле третьего лица
  //   let data = {}
  //   data[payload.field_name] = payload.value
  //   // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
  //   context.commit('UPDATE_THIRD_PARTY_SUBJECT', { ...data, id: payload.form.id })
  //
  //   const result = await api.query(
  //     'UPDATE',
  //     `/companies/${payload.company}/third_party_subjects/${payload.form.id}/`,
  //     context,
  //     data,
  //   )
  //
  //   if (!result) {
  //     // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
  //     await context.dispatch('fetchThirdPartySubjects', {
  //       company: payload.form.company,
  //       third_party_id: payload.form.id,
  //     })
  //   }
  // }, 100),

  // Apps actions
  async fetchApps(context, inn) {
    const appClient = new AppClient()
    const result = await appClient.fetchAll(inn)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_APPS', result.data)
    }
  },

  async addApp(context, payload) {
    const appClient = new AppClient()
    const result = await appClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_APP', result.data)
      return result.data
    }
  },

  async deleteApp(context, payload) {
    const appClient = new AppClient()
    const result = await appClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_APP', payload.id)
    }
  },

  updateApp: _.debounce(async (context, payload) => {
    // Обновляем систему
    let data = {}
    data[payload.field_name] = payload.value
    const appClient = new AppClient()
    const result = await appClient.update({
      ...payload,
      id: payload.id,
      fields: data,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_APP', result.data)
    }
  }, 100),

  // App forms actions
  async fetchAppForms(context, payload) {
    const appFormsClient = new AppFormsClient()
    const result = await appFormsClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_APP_FORMS', result.data)
    }
  },

  async addAppForm(context, payload) {
    const appFormsClient = new AppFormsClient()
    const result = await appFormsClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_APP_FORM', result.data)
      return result.data
    }
  },

  async deleteAppForm(context, payload) {
    const appFormsClient = new AppFormsClient()
    const result = await appFormsClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_APP_FORM', payload.id)
    }
  },

  updateAppForm: _.debounce(async (context, payload) => {
    // Обновляем систему
    let data = {}
    data[payload.field_name] = payload.subjects
    const appFormsClient = new AppFormsClient()
    const result = await appFormsClient.update({
      ...payload,
      id: payload.id,
      fields: data,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_APP_FORM', result.data)
    }
  }, 100),

  // Sites actions
  async fetchSites(context, payload) {
    const companySitesClient = new CompanySitesClient()
    const result = await companySitesClient.fetchAll(payload)
    const sites = result.data
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SITES', sites)
    }
    // Если есть сайты, которые сейчас сканируются, через 20 секунд скачаем сайты заново
    if (sites?.filter(s => s.scan_status === 'in progress').length) {
      setTimeout(() => context.dispatch('fetchSites', { company_id: payload.company_id }), 20000)
    } else {
      await context.dispatch('fetchSiteForms', { company_id: payload.company_id })
    }
  },

  async addSite(context, payload) {
    const companySitesClient = new CompanySitesClient()
    const result = await companySitesClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SITE', result.data)
      await context.dispatch('fetchSites', { company_id: payload.company_id })
      return result.data
    }
  },

  async deleteSite(context, payload) {
    const companySitesClient = new CompanySitesClient()
    const result = await companySitesClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SITE', payload.id)
    }
  },

  updateSite: _.debounce(async (context, payload) => {
    // Обновляем поле сайта
    let data = {}
    data[payload.field_name] = payload.value

    const companySitesClient = new CompanySitesClient()
    const result = await companySitesClient.update({
      ...payload,
      id: payload.id,
      fields: data,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SITE', result.data)
    }
  }, 300),

  async fetchSiteCookie(context, payload) {
    const companySiteCookieClient = new CompanySiteCookieClient()
    const result = await companySiteCookieClient.fetch(payload)
    await checkApiResponse(result, context)
  },

  async getCookie(context, payload) {
    const siteCookieStatusClient = new SiteCookieStatusClient()
    const result = await siteCookieStatusClient.fetchAll(payload)
    if (result.data.status === 'is processing') {
      setTimeout(() => {
        siteCookieStatusClient.fetchAll(payload)
      }, 10000)
    } else {
      await siteCookieStatusClient.fetchAll(payload)
    }
  },

  // Site_forms actions
  async fetchSiteForms(context, payload) {
    const companySiteFormsClient = new CompanySiteFormsClient()
    const result = await companySiteFormsClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SITE_FORMS', result.data)
    }
  },

  async addSiteForm(context, payload) {
    const companySiteFormsClient = new CompanySiteFormsClient()
    const result = await companySiteFormsClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SITE_FORM', result.data)
      return result.data
    }
  },

  async deleteSiteForm(context, payload) {
    const companySiteFormsClient = new CompanySiteFormsClient()
    const result = await companySiteFormsClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SITE_FORM', payload.id)
    }
  },

  updateSiteForm: _.debounce(async (context, payload) => {
    // Обновляем формы
    let data = {}
    data[payload.field_name] = payload.value

    const companySiteFormsClient = new CompanySiteFormsClient()
    const result = await companySiteFormsClient.update({
      ...payload,
      id: payload.id,
      fields: data,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SITE_FORM', result.data)
    }
  }, 300),

  // Документы
  // Получить документ
  async fetchDocument(context, payload) {
    const response = await api.fetch({ url: `/api/v2/documents/${payload.uuid}/` })
    if (response.errors.length) {
      // context.commit('DANGER_TOAST', i18n.global.t('documents.loading_error'), {root: true})
      return
    }
    context.commit('ADD_DOCUMENT', response.data)
    return response.data
  },

  async addDocument(context, payload) {
    // Добавляем документ
    if (payload.link?.length) {
      const response = await api.add({ url: '/api/v2/documents/', data: { link: payload.link } })
      if (response.status) {
        context.commit('ADD_DOCUMENT', response.data)
        return response.data
      } else {
        context.commit('DANGER_TOAST', response.errors[0].response.data.link[0], { root: true })
        return false
      }
    }

    // Валидация
    let filetype = payload.file.name.toLowerCase().match(/\.(\w+)$/)[1]
    let max_filesize = 30
    let allowed_types = []
    const allowedTypesMap = {
      doc: ['doc', 'docx', 'pdf', 'txt'],
      xls: ['doc', 'docx', 'pdf', 'txt', 'xls', 'xlsx'],
    }
    allowed_types = allowedTypesMap[payload.filetype] || ['jpg', 'jpeg', 'png', 'gif']

    if (filetype && !allowed_types.includes(filetype)) {
      context.commit(
        'DANGER_TOAST',
        `Добавлен файл неверного формата. Добавьте файл формата: ${allowed_types.join(', ')}`,
        { root: true },
      )
      return
    }

    // check size
    if (Number(payload.file.size) > max_filesize * 1048576) {
      context.commit(
        'DANGER_TOAST',
        `Превышен допустимый размер загружаемого файла. Максимальный размер: ${max_filesize} мб`,
        { root: true },
      )
      return
    }

    // Загружаем файл
    const fd = new FormData()
    fd.set('filename', payload.file.name)
    fd.append('file', payload.file, payload.file.name)

    const response = await api.add({ url: '/api/v2/documents/', data: fd })
    if (response.errors.length) {
      context.commit('DANGER_TOAST', 'Ошибка во время добавления файла', { root: true })
      return
    }

    context.commit('ADD_DOCUMENT', response.data)
    return response.data
  },

  updateDocument: _.debounce(async (context, payload) => {
    // Обновляем документ
    let data = {}
    data[payload.field_name] = payload.value
    await api.query(
      'UPDATE',
      `/documents/${payload.uuid}/`,
      context,
      data,
      '',
      '',
      i18n.global.t('document.message.success'),
      { root: true },
    )
  }, 500),

  async deleteDocument(context, payload) {
    // Удаляет документ
    return await api.query('DELETE', `/documents/${payload.uuid}/`, context, payload, 'DEL_DOCUMENT')
  },

  // Риски
  async sendForm(context, payload) {
    try {
      const companyCheckRisksClient = new CompanyCheckRisksClient()
      const result = await companyCheckRisksClient.fetchAll(payload)
      await context.dispatch('fetchRisks', { company_id: payload.company_id })
      if (await checkApiResponse(result, context)) {
        return true
      }
    } catch {
      return false
    }
  },

  // Чтобы после каждого изменения не скачивать список обновленных анкет можно обновить их в store
  calc_risks_in_lists(context, payload) {
    switch (payload.form_type) {
      case 'process':
        payload.context.commit(
          'process/CALC_RISKS_IN_LIST',
          { id: payload.form_id, risks: context.state.risks },
          { root: true },
        )
        break
      case 'system':
        payload.context.commit(
          'system/CALC_RISKS_IN_LIST',
          { id: payload.form_id, risks: context.state.risks },
          { root: true },
        )
        break
    }
  },

  async fetchRisks(context, payload) {
    try {
      const companyRisksClient = new CompanyRisksClient()
      const result = await companyRisksClient.fetchAll(payload)
      if (await checkApiResponse(result, context)) {
        context.commit('SET_RISKS', result.data)
        return result.data
      }
    } catch (error) {
      context.commit('DANGER_TOAST', 'Ошибка во время загрузки рисков', { root: true })
    }
    return false
  },

  updateRisk: _.debounce(async (context, payload) => {
    // Обновляем поле риска
    const companyRisksClient = new CompanyRisksClient()
    const result = await companyRisksClient.update({
      company_id: payload.company_id,
      id: payload.risk.id,
      fields: payload.risk,
    })

    if (await checkApiResponse(result, context)) {
      context.commit('SET_RISK', result.data)
    }

    if (!result) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('fetchRisks', { company_id: payload.company_id })
    }
    await context.dispatch('calc_risks_in_lists', {
      context,
      form_type: result.data.form,
      form_id: result.data.object_id,
    })
  }, 500),

  async fetchComments(context, payload) {
    const companyCommentsClient = new CompanySectionCommentClient()
    const result = await companyCommentsClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_COMMENTS', result.data)
    }
  },

  async addComments({ commit }, payload) {
    const result = await api.add({ url: `/api/v2/companies/${payload.company_id}/comments/`, data: payload })
    commit('ADD_COMMENT', result.data)
    return !result.errors.length
  },

  async deleteComment({ dispatch }, payload) {
    const result = await api.delete({
      url: `/api/v2/companies/${payload.company_id}/comments/${payload.current_index}/`,
    })
    dispatch('fetchComments', { company_id: payload.company_id })
    return !result.errors.length
  },

  // Отправка уведомления ответственному после того как анкета была заполнена
  async sendNotification(context, payload) {
    try {
      const subscriptionsNotificationClient = new SubscriptionsNotificationClient()
      const result = await subscriptionsNotificationClient.fetchAll(payload)
      if (await checkApiResponse(result, context)) {
        return true
      }
    } catch {
      return false
    }
  },

  async fetchSubscriptions(context, payload) {
    const companySubscriptionsClient = new CompanySubscriptionsClient()
    const result = await companySubscriptionsClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SUBSCRIPTIONS', result.data)
    }
  },

  async addSubscription(context, payload) {
    const companySubscriptionsClient = new CompanySubscriptionsClient()
    const result = await companySubscriptionsClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SUBSCRIPTION', result.data)
      return result.data
    }
  },

  async deleteSubscription(context, payload) {
    const companySubscriptionsClient = new SubscriptionsClient()
    const result = await companySubscriptionsClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DEL_SUBSCRIPTION', payload.id)
    }
  },

  async updateSubscription(context, payload) {
    const subscriptionsClient = new SubscriptionsClient()
    const result = await subscriptionsClient.update({ ...payload })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SUBSCRIPTION', result.data)
    }
  },

  async fetchMailingDate(context, payload) {
    const companyMailingClient = new CompanyMailingClient()
    const result = await companyMailingClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      // Загружаем emails для рассылки в store
      context.commit('SET_MAILING_DATE', result.data)
    }
  },

  async addMailingDate(context, payload) {
    const companyMailingClient = new CompanyMailingClient()
    const result = await companyMailingClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_MAILING_DATE', result.data)
    }
  },

  async deleteMailingDate(context, payload) {
    const companyMailingClient = new CompanyMailingClient()
    const result = await companyMailingClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DEL_MAILING_DATE', payload.id)
    }
  },

  async fetchDashboardData(context, payload) {
    await context.commit('dashboard/CHANGE_DASHBOARD_LOADING', true, { root: true })
    try {
      const result = await api.fetch({ url: `/api/v2/companies/${payload.company}/${payload.data}/details/` })
      context.commit('SET_DETAIL', result.data)
      await context.commit('dashboard/CHANGE_DASHBOARD_LOADING', false, { root: true })
    } catch (error) {
      context.commit('DANGER_TOAST', 'Ошибка во время загрузки рисков', { root: true })
    }
  },

  async checkEditorEmail(context, payload) {
    const companyEditorEmailClient = new CompanyEditorEmailClient()
    const result = await companyEditorEmailClient.fetchAll(payload)
    if (result.data.email?.length) {
      context.commit('SET_EDITOR_EMAIL', result.data.email)
    }
    return !result.errors?.length
  },

  async addEditorEmail(context, payload) {
    const result = await api.add({
      url: `/api/v2/companies/${payload.company_id}/history/editor_email/`,
      data: payload.data,
    })
    if (result.data.email?.length) {
      context.commit('SET_EDITOR_EMAIL', result.data.email)
    }
    return !result.errors.length
  },

  async fetchSectionsComments(context, payload) {
    const companySectionCommentClient = new CompanySectionCommentClient()
    const result = await companySectionCommentClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SECTIONS_COMMENTS', result.data)
    }
  },

  async addSectionComment(context, payload) {
    const addSectionCommentClient = new AddSectionCommentClient()
    const result = await addSectionCommentClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SECTION_COMMENT', result.data)
    }
  },

  async updateSectionComment(context, payload) {
    const companySectionCommentClient = new CompanySectionCommentClient()
    const result = await companySectionCommentClient.update({ ...payload })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SECTION_COMMENT', result.data)
    }
  },

  async deleteSectionComment(context, payload) {
    const companySectionCommentClient = new CompanySectionCommentClient()
    const result = await companySectionCommentClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SECTION_COMMENT', payload.id)
    }
  },

  async fetchAllSystems(context, payload) {
    const result = await api.fetch({ url: `/api/v2/companies/${payload.company_id}/systems/` })
    if (result.status) {
      context.commit('system/SET_SYSTEMS', result.data, { root: true })
    }
  },

  async fetchAddressItems(context, payload) {
    const companyAddressItemsClient = new CompanyAddressItemsClient()
    const result = await companyAddressItemsClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_ADDRESS_ITEMS', result.data)
    }
  },

  async addAddressItem(context, payload) {
    const result = await api.add({
      url: `/api/v2/companies/${payload.company_id}/address_items/`,
      data: payload.fields,
    })
    // const companyAddressItemsClient = new CompanyAddressItemsClient()
    // const result = await companyAddressItemsClient.create(payload)
    if ((await checkApiResponse(result, context)) && result.data) {
      context.commit('ADD_ADDRESS_ITEM', result.data)
      return result.data
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('general.errors.name'), { root: true })
    }
  },

  async deleteAddressItem({ commit }, item) {
    const result = await api.delete({ url: `/api/v2/companies/${item.company_id}/address_items/${item.id}/` })
    if (result.status) {
      commit('DELETE_ADDRESS_ITEM', item.id)
    } else {
      return result.errors[0].response.data
    }
  },

  async updateAddressItem(context, payload) {
    let data = {}
    data[payload.field_name] = payload.value

    const companyAddressItemsClient = new CompanyAddressItemsClient()
    const result = await companyAddressItemsClient.update({
      company_id: payload.company_id,
      id: payload.id,
      fields: data,
    })
    if ((await checkApiResponse(result, context)) && !!result.data) {
      context.commit('UPDATE_ADDRESS_ITEM', result.data)
    } else {
      context.commit('DANGER_TOAST', 'Не получилось изменить адрес, возможно название не уникально', { root: true })
    }
  },

  async sendLinkForm(context, payload) {
    try {
      await api.client.post(`${context.rootState.server}/api/v2/companies/${payload.company}/mail/send_link/`, payload)
      context.commit(
        'SUCCESS_TOAST',
        i18n.global.t('general.modal.link.message.success') + `${payload.responsible_emails}`,
        { root: true },
      )
    } catch (error) {
      context.commit('DANGER_TOAST', i18n.global.t('general.modal.link.message.error'), { root: true })
    }
  },

  async sendLinkTags(context, payload) {
    try {
      await api.client.post(
        `${context.rootState.server}/api/v2/companies/${payload.company}/mail/send_link_tags/`,
        payload,
      )
      context.commit(
        'SUCCESS_TOAST',
        i18n.global.t('general.modal.link.message.success') + `${payload.responsible_emails}`,
        { root: true },
      )
    } catch (error) {
      context.commit('DANGER_TOAST', i18n.global.t('general.modal.link.message.error'), { root: true })
    }
  },

  async fetchMailing(context, payload) {
    const companyMailingContextClient = new CompanyMailingContextClient()
    const result = await companyMailingContextClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_MAILING_CONTEXT', result.data)
    }
  },

  async editMailing(context, payload) {
    try {
      const companyMailingContextClient = new CompanyMailingContextClient()
      const result = await companyMailingContextClient.update({
        company_id: payload.company_id,
        fields: { ...payload },
      })
      if (await checkApiResponse(result, context)) {
        context.commit('SET_MAILING_CONTEXT', result.data)
        context.commit('SUCCESS_TOAST', i18n.global.t('settings.mailing.custom.success'), { root: true })
      }
    } catch (error) {
      context.commit('DANGER_TOAST', i18n.global.t('settings.mailing.custom.warn.update'), { root: true })
    }
  },

  async fetchCompanyThirdPartiesDetails(context, company_id) {
    const result = await api.fetch({ url: `/api/v2/companies/${company_id}/third_parties/details` })
    if (result.status) {
      context.commit('SET_COMPANY_THIRD_PARTIES', result.data)
    }
  },

  //Проверяем была ли ранее создана модель угроз - негативные последствия
  async fetchThreat(context, company_id) {
    const result = await api.fetch({ url: `/api/v2/companies/${company_id}/threats/` })
    if (result.status) {
      context.commit('SET_THREAT_FORM', result.data)
    }
  },

  async fetchCriminals(context, company_id) {
    const result = await api.fetch({ url: `/api/v2/companies/${company_id}/criminals/` })
    if (result.status) {
      context.commit('SET_CRIMINALS', result.data)
    }
  },

  async fetchSecurityTechnologies(context, company_id) {
    const result = await api.fetch({ url: `/api/v2/companies/${company_id}/security_technologies/` })
    if (result.status) {
      context.commit('SET_SECURITY_TECHNOLOGIES', result.data)
    }
  },

  async fetchThreatAssesments(context, company_id) {
    const result = await api.fetch({ url: `/api/v2/companies/${company_id}/threat_assesments/` })
    if (result.status) {
      context.commit('SET_THREAT_ASSESMENTS', result.data)
    }
  },

  async addUserToCompany(context, payload) {
    const result = await api.fetch({
      url: `/api/v2/users/${payload.user.id}/add_to_company/?company=${payload.company_id}`,
    })
    if (result.status) {
      context.commit('ADD_USER_COMPANY', payload.user.id)
      const success_add = `${payload.user.username} ${i18n.global.t('management.users.create.message')}`
      context.commit('SUCCESS_TOAST', success_add, { root: true })
    }
  },
}
