export default {
  user: {
    id: null,
    username: null,
    status: null,
    is_analyst: false,
    is_client: false,
    is_student: false,
    is_superuser: false,
  },

  info_message: '',
  info_link: '',

  need_login: false,

  is_public_mode: false,

  users: [],
  perms: [{id: 1, user: 2},],
  permissions: [],

  show_change_pwd: false,
}
