<template>
  <section
    v-click-outside="closeBoard"
    class="input_section"
  >
    <label>
      <p class="input_name">{{ label_name }}</p>
      <div class="text_input">
        <input
          :id="`auto_complete_${unique_id}`"
          ref="input"
          v-model="text"
          type="text"
          @click="show = !show"
          @input="input"
        />

        <transition name="fastfade">
          <div
            v-show="show"
            class="input_dropdown"
          >
            <div class="input_dropdown__title">
              <span> Показано {{ value ? filtered_elements.length : available_elements.length }} </span> из
              {{ available_elements.length }}
            </div>
            <div
              v-for="(elem, index) in filtered_elements"
              :key="index"
              class="input_dropdown__elem"
              @click="selectElem(elem)"
            >
              <span>{{ elem }}</span>
            </div>
          </div>
        </transition>
      </div>
    </label>
  </section>
</template>

<script>
/*
 * Этот компонент нужен для выпадающих элементов и выбора из этого списка, а также поиска по этому списку
 * Когда focus на инпуте показывается выпадающее меню, при вводе эти элементы фильтруются и сверху показывается плашка
 * с "Показано 4 из 36 элементов"
 */
import { mapMutations } from 'vuex'

export default {
  name: 'AutoCompleteEmit',

  props: {
    value: {
      type: String,
      default: '',
    },

    tagId: {
      type: String,
      default: '',
    },

    available_elements: Array,
    field_name: String,
    label_name: String,
  },

  data() {
    return {
      show: false,
      text: this.value,
      start_typing: false,
      unique_id: '',
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  computed: {
    filtered_elements() {
      return this.available_elements.filter(element => element.toLowerCase().includes(this.text.toLowerCase()))
    },
  },

  watch: {
    value: function (val) {
      this.text = val
    },
  },

  methods: {
    ...mapMutations('security', ['UPDATE_SECURITY_FORM']),

    closeBoard() {
      this.show = false
    },

    input(e) {
      this.text = e.target.value
    },

    selectElem(element) {
      this.$emit('update', this.field_name, element)
      this.closeBoard()
      this.$refs.input.blur()
    },
  },
}
</script>

<style scoped lang="sass">
@use "@/assets/sass/general.sass"
</style>
