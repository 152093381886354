<template>
  <section class="input_section">
    <div>
      <div class="taginput_name">
        <p
          :id="`tag_delete_obj_${unique_id}`"
          class="input_name"
        >
          {{ label_name ? label_name : '' }}
          <span v-if="filling_status">
            <img
              v-if="value.length"
              class="checkmark"
              src="@/assets/img/green-check.svg"
              alt=""
            />
            <img
              v-else
              class="checkmark no_filled"
              src="@/assets/img/black-check.svg"
              alt=""
            />
          </span>
        </p>
      </div>
      <div
        v-click-outside="closeData"
        class="fold_text_input"
      >
        <div class="add_input">
          <form @submit.prevent="addElementManual({ name: new_element_name })">
            <div :class="['data_wrapper', 'exist', { active_scroll: !!public_key?.comments_only }]">
              <div
                v-for="item in values_list"
                :id="`exists_elements_${item.id}`"
                :key="item.id"
                :class="['data_el', { 'disabled-block': !!public_key?.comments_only }]"
              >
                {{ item.name }}
                <img
                  v-if="!item.is_created_in_process"
                  src="@/assets/img/close-icon.svg"
                  alt="close"
                  @click="deleteElementOrModal(item.id)"
                />
              </div>
              <input
                :id="`default_elements_${tagId}`"
                ref="input"
                v-model="new_element_name"
                :placeholder="placeholder"
                @focus="show_fold_data = true"
              />
            </div>
          </form>
        </div>

        <div
          v-if="show_fold_data"
          class="fold"
        >
          <div
            v-if="filtered_available_elements.length"
            class="data_wrapper"
          >
            <div
              v-for="element in filtered_available_elements"
              :id="`default_elements_${element.id}`"
              :key="element.id"
              class="data_el"
              @click="addElement(element)"
            >
              {{ element.name }}
            </div>
          </div>

          <!-- Этот блок показывается, если нет значений и нельзя добавлять руками-->
          <div
            v-if="!filtered_available_elements.length"
            class="absent_el"
          >
            <img
              src="@/assets/img/fire-icon.svg"
              alt="fire"
            />
            <p>{{ $t('forms.process.5.per.not_values_def') }}</p>
          </div>

          <div class="new_field_wrapper">
            <div class="data_wrapper">
              <span style="margin-right: 15px">
                {{ new_element_name.length ? $t('general.create') : $t('general.typing') }}</span
              >
              <div
                v-if="new_element_name.length"
                class="data_el binded yellow"
              >
                {{ new_element_name }}
              </div>
            </div>
            <div
              id="add_elem_btn"
              class="press_btn"
              @click="addElementManual({ name: new_element_name })"
            >
              <p>{{ $t('general.press') }}</p>
              <img
                src="@/assets/img/icon-keyboard-enter.svg"
                alt="keyboard"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <delete-modal
      :show="show_delete_modal"
      :title="$t('general.modal.delete.common.title')"
      :desc="$t('general.modal.delete.common.desc')"
      @close="show_delete_modal = false"
      @approve="deleteElement(delete_index)"
    ></delete-modal>
  </section>
</template>
<script>
import TagInputMixin from '@/components/global/inputs/mixins/TagInputMixin'
import DeleteModal from '@/components/global/modals/deleteModal.vue'
import { mapState } from 'vuex'

export default {
  name: 'TagDeleteObject',
  components: { DeleteModal },

  mixins: [TagInputMixin],

  props: {
    label_name: String,
    value: Array,
    not_set_values: {
      type: String,
      default: '',
    },
    available_elements: {
      type: Array,
      default: () => [],
    },
    compare_by: {
      type: String,
      default: 'id',
    },

    placeholder: String,
    new_element: {
      type: Object,
      default: () => {},
    },

    tagId: {
      type: String,
      default: '',
    },
    filling_status: Boolean,
  },

  data() {
    return {
      show_fold_data: false,
      new_element_name: '',
      show_delete_modal: false,
      delete_index: null,
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  computed: {
    ...mapState('company', ['public_key']),

    values_list() {
      let new_ava = this.available_elements.map(a => a.name)
      return this.value.filter(el => new_ava?.includes(el.name))
    },

    filtered_available_elements() {
      const names = this.value.map(obj => obj.name)
      return this.available_elements.filter(
        el => !names.includes(el.name) && el.name.toLowerCase().indexOf(this.new_element_name.toLowerCase()) !== -1,
      )
    },
  },

  methods: {
    addElement(element) {
      if (element.name.length) {
        this.$emit('add', { ...element, ...this.new_element })
        this.new_element_name = ''
      }
    },

    addElementManual(element) {
      if (!element.name.length) {
        return
      }
      if (this.values_list.map(i => i.name).includes(element.name)) {
        this.DANGER_TOAST(`${element.name} ${this.$t('general.errors.value_input_error')}`)
        return
      }
      if (this.available_elements.map(i => i.name).includes(element.name)) {
        this.addElement(element)
        return
      }
      this.$emit('addManual', { ...element, ...this.new_element })
      this.new_element_name = ''
    },

    deleteElementOrModal(index) {
      this.show_delete_modal = true
      this.delete_index = index
    },

    deleteElement(index) {
      this.$emit('delete', index)
      this.show_delete_modal = false
    },
  },
}
</script>
