// Функции для отображения дат в формате "25 февраля 2022 г."
export function datetimeToString(datetime) {
  try {
    const date = new Date(datetime)
    return date.toLocaleString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' })
  } catch {
    return null
  }
}
// Функции для отображения даты в формате "2024-02-25"
export function dateToISODate(datetime) {
  try {
    const date = new Date(datetime)
    return date.toISOString().split('T')[0]
  } catch {
    return null
  }
}
// Функции для отображения даты и времени в формате "14:01 15.07.2024"
export const formatDateTime = datetime => {
  if (datetime) {
    return `${formatTime(datetime)} ${formatDate(datetime)}`
  }
  return ''
}
// Функции для отображения времени в формате "14:01"
const formatTime = time => {
  let rawDate = new Date(time)
  return `${addZero(rawDate.getHours())}:${addZero(rawDate.getMinutes())}`
}
// Функции для отображения даты в формате "15.07.2024"
export const formatDate = time => {
  let rawDate = new Date(time)
  return `${addZero(rawDate.getDate())}.${addZero(rawDate.getMonth() + 1)}.${rawDate.getFullYear()}`
}
const addZero = val => { return ("0" + val).slice(-2) }
