<template>
  <section class="input_section">
    <div>
      <div class="taginput_name">
        <p class="input_name">{{ label_name ? label_name : '' }}</p>
        <button
          v-if="clean_field"
          class="taginput_clean"
          @click="$emit('update', field_name, '')"
        >
          Очистить поле
        </button>
      </div>

      <div
        v-click-outside="closeData"
        class="fold_text_input"
      >
        <div class="add_input">
          <form @submit.prevent="addElementManual(new_element_name)">
            <div class="data_wrapper exist">
              <div
                v-for="(elem, index) in values_list"
                :id="`exists_elements_${index + 1}`"
                :key="elem.id"
                :class="['data_el']"
              >
                {{ elem }}
                <img
                  src="@/assets/img/close-icon.svg"
                  @click="deleteElementOrModal(index)"
                />
              </div>

              <input
                :id="`default_elements_${unique_id}`"
                ref="input"
                v-model="new_element_name"
                :placeholder="placeholder"
                @focus="show_fold_data = true"
              />
            </div>
          </form>
        </div>

        <div
          v-if="show_fold_data"
          class="fold"
        >
          <div
            v-if="filtered_available_elements.length"
            class="data_wrapper"
          >
            <div
              v-for="(element, index) in filtered_available_elements"
              :id="`default_elements_${index + 1}`"
              :key="index"
              class="data_el"
              @click="addElement(element)"
            >
              {{ element }}
            </div>
          </div>
          <div class="absent_el">
            <p>{{ not_set_values }}</p>
          </div>

          <!-- Этот блок показывается, если нет значений и нельзя добавлять руками-->
          <div
            v-if="!filtered_available_elements.length && no_manual"
            class="absent_el"
          >
            <img src="@/assets/img/fire-icon.svg" />
            <p>{{ $t('forms.process.5.per.not_values_def') }}</p>
          </div>

          <div
            v-if="!no_manual"
            class="new_field_wrapper"
          >
            <div class="data_wrapper">
              <span style="margin-right: 15px">
                {{ new_element_name.length ? $t('general.create') : $t('general.typing') }}
              </span>

              <div
                v-if="new_element_name.length"
                class="data_el binded yellow"
              >
                {{ new_element_name }}
              </div>
            </div>
            <div
              id="add_elem_btn"
              class="press_btn"
              @click="addElement(new_element_name)"
            >
              <p>{{ $t('general.press') }}</p>
              <img src="@/assets/img/icon-keyboard-enter.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <delete-modal
      :show="show_delete_modal"
      :title="$t('general.modal.delete.common.title')"
      :desc="$t('general.modal.delete.common.desc')"
      @close="show_delete_modal = false"
      @approve="deleteElement(current_index)"
    ></delete-modal>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      show_fold_data: false,
      new_element_name: '',
      show_delete_modal: false,
      current_index: 0,
      unique_id: '',
    }
  },

  computed: {
    ...mapState('user', ['user']),
    values_list() {
      if (typeof this.value === 'object') {
        return this.available_elements.filter(el => this.value.includes(el.id)).map(el => el.name)
      } else {
        return this.value?.split(/\s*;\s*/).filter(el => el.length)
      }
    },

    filtered_available_elements() {
      if (typeof this.value === 'object') {
        return this.available_elements.filter(el => !this.value.includes(el.id)).map(el => el.name)
      } else {
        return this.available_elements.filter(elem => {
          return !this.values_list.includes(elem) && elem.toLowerCase().includes(this.new_element_name.toLowerCase())
        })
      }
    },
  },

  methods: {
    ...mapMutations(['DANGER_TOAST']),

    focusOnInput() {
      setTimeout(() => {
        this.$refs.input.focus()
        this.$refs.input.scrollTop = 9999
      }, 0)
    },

    addElementManual(element) {
      if (!this.no_manual || this.user.is_analyst) {
        this.addElement(element)
      } else {
        this.DANGER_TOAST('В данном поле вы можете добавить только из предложенных значений')
      }
    },

    cleanField() {
      this.$emit('update', '')
    },

    addElement(element) {
      if (typeof this.value === 'object') {
        this.$emit(
          'update',
          this.field_name,
          this.value.concat(this.available_elements.filter(el => el.name === element).map(el => el.id)),
        )
      } else if (element.length) {
        this.values_list.push(element)
        this.$emit('update', this.field_name, this.values_list.join(';'))
        this.new_element_name = ''
        this.focusOnInput()
      }
    },

    deleteElementOrModal(index) {
      if (this.approve) {
        this.show_delete_modal = true
        this.current_index = index
      } else {
        this.deleteElement(index)
      }
    },

    deleteElement(index) {
      if (typeof this.value === 'object') {
        this.$emit(
          'update',
          this.field_name,
          this.value.filter(
            el =>
              !this.available_elements
                .filter(el => el.name === this.values_list[index])
                .map(el => el.id)
                .includes(el),
          ),
        )
      } else {
        this.values_list.splice(index, 1)
        this.$emit('update', this.field_name, this.values_list.join(';'))
      }

      if (this.approve) {
        this.show_delete_modal = false
      }
    },

    closeData() {
      this.show_fold_data = false
    },
  },
}
</script>

<style scoped lang="sass">
@use "@/assets/sass/style.sass"
</style>
