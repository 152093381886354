<template>
  <section
    :class="[!large ? 'input_section':'']"
    tabindex="0"
    @keypress="letters += $event.key"
  >
    <p
      :id="`field_name_${unique_id}`"
      class="input_name"
    >
      {{ field_name }}
      <img
        v-if="!!hint"
        style="opacity: 0.5"
        src="@/assets/img/question.svg"
        :title="hint"
      />
    </p>

    <div
      v-click-outside="closeSelect"
      class="custom_select"
    >
      <div
        :class="['parent_block']"
        style="height: auto"
        @click="show_fold_menu = true"
      >
        <div v-if="selected_field.length">{{ getFieldValue() }}</div>
        <div
          v-else
          class="no_filled"
        >
          {{ placeholder }}
        </div>
        <div
          v-if="show_fold_menu"
          class="icon"
        >
          <img
            src="@/assets/img/arrow-down-gray.svg"
            alt="arrow-down-gray"
          />
        </div>
        <div
          v-if="!show_fold_menu"
          class="icon"
        >
          <img
            src="@/assets/img/arrow-up-gray.svg"
            alt="arrow-up-gray"
          />
        </div>
      </div>

      <div
        v-if="show_fold_menu"
        class="fold_block"
        style="overflow-y: scroll; max-height: 500px"
      >
        <div
          v-for="(option, index) in filtered_elements"
          :key="option.id || index"
          :class="['block', isValueEqualField(option) ? 'selected' : '']"
          :style="computedStyle(option)"
          @click="selectValue(option)"
        >
          <div class="text">{{ typeof option === 'object' ? option.name : option }}</div>
          <div class="icon">
            <img
              v-if="isValueEqualField(option)"
              src="@/assets/img/check-icon.svg"
              alt="check-icon"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'MultiSelectInput',

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    value: String,
    field_name: String,
    available_elements: Array,
    placeholder: String,
    hint: String,
    narrow: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    tagId: String,
  },

  data() {
    return {
      show_fold_menu: false,
      selected_field: [],
      letters: '',
      unique_id: '',
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
    if (this.value) {
      this.selected_field = this.value.split(';')
    } else {
      this.selected_field = [...this.modelValue]
    }
    this.debouncedResetLetters = _.debounce(this.resetLetters, 5000)
  },

  watch: {
    letters: {
      handler() {
        this.debouncedResetLetters()
      },
    },
  },

  computed: {
    filtered_elements() {
      if (typeof this.value === 'object') {
        return this.available_elements.filter(el => !this.value.includes(el.id)).map(el => el.name)
      } else {
        return this.available_elements.filter(elem => {
          return elem.toLowerCase().startsWith(this.letters.toLowerCase())
        })
      }
    },
  },

  methods: {
    closeSelect() {
      this.show_fold_menu = false
      this.letters = ''
    },

    getFieldValue() {
      const selectedNames = this.selected_field
        .map(fieldValue => {
          const foundOption = this.available_elements.find(option => {
            if (typeof option === 'object') {
              return option.value === fieldValue
            } else {
              return option === fieldValue
            }
          })

          if (foundOption) {
            return typeof foundOption === 'object' ? foundOption.name : foundOption
          }
        })
        .filter(Boolean)

      return selectedNames.join(', ')
    },

    isValueEqualField(value) {
      if (typeof value === 'object') {
        return this.selected_field.includes(String(value.value))
      } else {
        return this.selected_field.includes(value)
      }
    },

    selectValue(option) {
      if (typeof option === 'object') {
        const index = this.selected_field.findIndex(selected => selected === option.value)
        if (index === -1) {
          this.selected_field.push(option.value)
        } else {
          this.selected_field.splice(index, 1)
        }
      } else {
        const index = this.selected_field.findIndex(selected => selected === option)
        if (index === -1) {
          this.selected_field.push(option)
        } else {
          this.selected_field.splice(index, 1)
        }
      }
      this.$emit('update', this.selected_field.join(';'))
      this.letters = ''
    },

    //Вычисляем отступы между строк
    computedStyle(option) {
      if (option.length > 200 && option.length < 300) {
        return 'height: 100px'
      } else if (this.narrow && option.length > 100) {
        return 'height: 150px'
      } else if (this.narrow && option.length > 250) {
        return 'height: 300px'
      }
      return ''
    },

    resetLetters() {
      this.letters = ''
    },
  },
}
</script>

<style scoped lang="sass">
@use "@/assets/sass/style.sass"

.input_section:focus
  outline: none
</style>
