import { api } from '@/main'
import i18n from '@/locales'
import store from '@/store'

const emptyAnswer = {
  data: null,
  error: null,
  sessionTimeout: false,
}

export async function checkApiResponse(result, context, showError = true) {
  // Метод для проверки ответа сервера
  if (result.sessionTimeout && context.rootState.auth_type === 'login' && !context.rootState.user.is_public_mode) {
    context.commit('WARNING_TOAST', i18n.global.t('logout.session_expired'), { root: true })
    await context.dispatch('user/logout', null, { root: true })
    return false
  }

  if (!result.error) {
    return true
  }

  if (showError) {
    context.commit('DANGER_TOAST', result.error, { root: true })
  }

  return false
}

class BaseClient {
  setLastActivity() {
    // Сохраняет в браузере, что последняя активность пользователя была сейчас
    localStorage.setItem('lastActivity', String(Date.now()))
  }

  checkSessionTimeout() {
    // Возвращает true если сессия устарела
    if (store.state?.user?.is_public_mode) {
      return false
    }
    const lastActivity = Number(localStorage.getItem('lastActivity'))
    const now = Date.now()
    return !!(lastActivity && now - lastActivity > 45 * 60 * 1000)
  }

  getRequiredFields() {
    // Можем определить список обязательных полей
    return ['company_id']
  }

  checkPayload(payload) {
    const requiredFields = this.getRequiredFields()
    requiredFields.forEach(field => {
    if (!(field in payload) || !['string', 'number'].includes(typeof payload.field)) {
        return false
      }
    })
    return true
  }

  getUrl(payload) {
    return `${api.api_url}/companies/${payload.company_id}/`
  }

  getUrlRoot() {
    return `${api.api_url}`
  }

  getObjectUrl(payload) {
    //Проверяем содержит ли url id
    // необходим только для анкет, для update вложенного
    // url в компанию id не нужен
    const has_id = payload.id ? `${payload.id}/` : ''
    return `${this.getUrl(payload)}${has_id}`
  }

  getResult(result, expected_status = 200, error = null) {
    if (expected_status === 400) {
      return result
    }
    if (!error && result.status === expected_status) {
      return { ...emptyAnswer, data: result.data }
    }
    return { ...emptyAnswer, error: error?.response.data.detail }
  }

  async create(payload) {
    if (this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()

    if (!this.checkPayload(payload)) {
      return { ...emptyAnswer, error: 'Payload is not valid' }
    }
    if (payload.fields?.length === 0) {
      return { ...emptyAnswer, error: 'Data is empty' }
    }

    try {
      const result = await api.client.post(`${this.getUrl(payload)}`, payload.fields)
      return this.getResult(result, 201)
    } catch (error) {
      if (error.response.data?.freemium) {
        return this.getResult(error.response, 400, error)
      }
      return this.getResult(null, 201, error)
    }
  }

  async fetch(payload) {
    if (this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()

    if (!this.checkPayload(payload)) {
      return { ...emptyAnswer, error: 'Payload is not valid' }
    }

    try {
      const result = await api.client.get(`${this.getObjectUrl(payload)}`)
      return this.getResult(result, 200)
    } catch (error) {
      return this.getResult(null, 200, error)
    }
  }

  async fetchAll(payload) {
    if (this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()

    if (!this.checkPayload(payload)) {
      return { ...emptyAnswer, error: 'Payload is not valid' }
    }

    let url = this.getUrl(payload)
    if ('limit' in payload || 'offset' in payload || 'ordering' in payload) {
      let params = []
      if ('limit' in payload) {
        params.push(`limit=${payload.limit}`)
      }
      if ('offset' in payload) {
        params.push(`offset=${payload.offset}`)
      }
      if ('ordering' in payload) {
        params.push(`ordering=${payload.ordering}`)
      }
      url += `?${params.join('&')}`
    }

    try {
      const result = await api.client.get(url)
      return this.getResult(result, 200)
    } catch (error) {
      return this.getResult(null, 200, error)
    }
  }

  async update(payload, checkTimeout = true) {
    if (checkTimeout && this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()

    if (!this.checkPayload(payload)) {
      return { ...emptyAnswer, error: 'Payload is not valid' }
    }
    if (payload.fields.length === 0) {
      return { ...emptyAnswer, error: 'Data is empty' }
    }

    try {
      const result = await api.client.patch(`${this.getObjectUrl(payload)}`, payload.fields)
      return this.getResult(result, 200)
    } catch (error) {
      return this.getResult(null, 200, error)
    }
  }

  async delete(payload) {
    if (this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()

    if (!this.checkPayload(payload)) {
      return { ...emptyAnswer, error: 'Payload is not valid' }
    }

    try {
      const result = await api.client.delete(`${this.getObjectUrl(payload)}`)
      return this.getResult(result, 200)
    } catch (error) {
      return this.getResult(null, 200, error)
    }
  }
}

class BaseClientWithProcess extends BaseClient {
  // Класс от которого надо наследовать объекты вложенные в процесс
  getRequiredFields() {
    return super.getRequiredFields().concat(['process_id'])
  }
}

class BaseClientWithSystem extends BaseClient {
  // Класс от которого надо наследовать объекты вложенные в систему
  getRequiredFields() {
    return super.getRequiredFields().concat(['system_id'])
  }
}

class BaseClientWithId extends BaseClient {
  getRequiredFields() {
    return super.getRequiredFields().concat(['id'])
  }
}

class BaseClientWithInit extends BaseClient {
  async init(payload) {
    if (this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()

    if (!this.checkPayload(payload)) {
      return { ...emptyAnswer, error: 'Payload is not valid' }
    }
    try {
      const result = await api.client.get(`${this.getObjectUrl(payload)}init_data/`)
      return this.getResult(result, 200)
    } catch (error) {
      return this.getResult(null, 200, error)
    }
  }
}

//Company
export class CompanyClient extends BaseClientWithInit {
  getRequiredFields() {
    return []
  }

  getUrl() {
    return `${api.api_url}/companies/`
  }

  getUrlRoot() {
    return `${api.api_url}/`
  }

  getObjectUrl(payload) {
    return `${this.getUrl(payload)}${payload.company_id}/`
  }
}

export class CopyCompanyClient extends CompanyClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}${payload.id}/copy/`
  }
}

export class CompanyInnClient extends CompanyClient {
  getUrl(inn) {
    return `${super.getUrl()}get_company_info/?inn=${inn}`
  }
}

export class CompanyInformationClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}information/`
  }
}

export class CompanySiteAppClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}site_app/`
  }
}

export class CompanyCommissionClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}commission/`
  }
}

export class CompanyDepartmentClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}department/`
  }
}

export class CompanySubjectsClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}subjects/`
  }
}

export class CompanyAllTagsClient extends CompanyClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}${payload.company_id}/all_tags/`
  }
}

export class CompanyTagsClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}tags/`
  }
}

export class CompanyRenameTagClient extends CompanyTagsClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}rename/`
  }
}

export class CompanyDirectoryItemsClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}directory_items/`
  }
}

export class CompanyMacroGoalClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}macro_goals/`
  }
}

export class KeyClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}keys/`
  }

  getObjectUrl(payload) {
    return `${this.getUrl(payload)}${payload.key}/`
  }
}

export class CompanyOfficesClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}offices/`
  }
}

export class CompanyThirdPartiesClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}third_parties/`
  }
}

export class AppClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}apps/`
  }
}

export class AppFormsClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}app_forms/`
  }
}

export class CompanySitesClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}sites/`
  }
}

export class CompanySiteCookieClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}sites/${payload.site}/request_cookies/`
  }
}

export class SiteCookieStatusClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}sites/${payload.site}/get_cookie_status/`
  }
}

export class CompanySiteFormsClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}site_forms/`
  }
}

export class CompanyRisksClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}risks/`
  }
}

export class CompanyCheckRisksClient extends CompanyRisksClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}check_risks/?form=${payload.name}&id=${payload.form.id}`
  }
}

export class CompanySubscriptionsClient extends CompanyClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}${payload.company_id}/subscriptions/`
  }
}

export class SubscriptionsClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}subscriptions/`
  }
}

export class SubscriptionsNotificationClient extends CompanyClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}/notification/?form_type=${payload.name}&form_id=${payload.form.id}`
  }
}

export class CompanyMailingClient extends CompanyClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}${payload.company_id}/mail/`
  }
}

export class CompanyEditorEmailClient extends CompanyClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}${payload.company_id}/history/editor_email/`
  }
}

export class CompanySectionCommentClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}comments/`
  }
}

export class AddSectionCommentClient extends BaseClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}comments/?content_type=${payload.fields.field_name}&object_id=${payload.fields.object_id}`
  }
}

export class CompanyAddressItemsClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}address_items/`
  }
}

export class CompanyMailingContextClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}mail_context/`
  }
}

// Process
export class ProcessClient extends BaseClientWithInit {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/`
  }
}

export class InitProcessClient extends BaseClientWithInit {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/init_data/`
  }
}

export class DefaultProcessesClient extends BaseClientWithInit {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/default_processes/`
  }
}

export class CopyProcessClient extends BaseClientWithInit {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process}/copy/`
  }
}

export class ProcessToActiveClient extends BaseClientWithId {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/inactive/`
  }
}

export class ProcessSubjectClient extends BaseClientWithProcess {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/process_subjects/`
  }
}

export class ProcessPositionClient extends BaseClientWithProcess {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/positions/`
  }
}

//Process source subjects
export class ProcessSourceSubjectClient extends BaseClientWithProcess {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/source_subjects/`
  }
}

export class ProcessPublicSourceClient extends BaseClientWithProcess {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/public_sources/`
  }
}

export class ProcessOtherSourceClient extends BaseClientWithProcess {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/other_sources/`
  }
}

// Paper docs
export class ProcessPaperDocClient extends BaseClientWithProcess {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/paper_docs/`
  }
}

export class ProcessSystemsClient extends BaseClientWithProcess {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/process_systems/`
  }
}

export class ProcessSystemsSubjectClient extends BaseClientWithProcess {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/process_system_subjects/`
  }
}

export class ProcessThirdPartyClient extends BaseClientWithProcess {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/process_third_parties/`
  }
}

export class ProcessThirdPartySubjectsClient extends BaseClientWithProcess {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/${payload.process_id}/process_third_party_subjects/`
  }
}

export class SearchProcessesClient extends BaseClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}processes/?search=${payload.search}`
  }
}

// Systems
export class SystemClient extends BaseClientWithInit {
  getUrl(payload) {
    return `${super.getUrl(payload)}systems/`
  }
}

export class InitSystemClient extends BaseClientWithInit {
  getUrl(payload) {
    return `${super.getUrl(payload)}systems/${payload.system_id}/init_data/`
  }
}

export class SystemOtherSourceClient extends BaseClientWithSystem {
  getUrl(payload) {
    return `${super.getUrl(payload)}systems/${payload.system_id}/system_source_others/`
  }
}

export class SystemSubjectClient extends BaseClientWithSystem {
  getUrl(payload) {
    return `${super.getUrl(payload)}systems/${payload.system_id}/system_subjects/`
  }
}

export class SystemThirdPartyClient extends BaseClientWithSystem {
  getUrl(payload) {
    return `${super.getUrl(payload)}systems/${payload.system_id}/system_third_parties/`
  }
}

export class SystemThirdPartySubjectsClient extends BaseClientWithSystem {
  getUrl(payload) {
    return `${super.getUrl(payload)}systems/${payload.system_id}/system_third_party_subjects/`
  }
}

export class SearchSystemsClient extends BaseClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}systems/?search=${payload.search}`
  }
}

export class SystemClientInteractions extends BaseClientWithSystem {
  getUrl(payload) {
    return `${super.getUrl(payload)}systems/${payload.system_id}/system_interactions/`
  }
}

export class HistoryClient extends BaseClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}history/`
  }
}

export class EmployeeClient extends BaseClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}employees/`
  }
}

export class PermissionClient extends BaseClient {
  getUrl(payload) {
    return `${super.getUrl(payload)}perms/`
  }
}

export class UserCompanyClient extends BaseClient {
  getUrl(payload) {
    return `${super.getUrlRoot()}/users/?company=${payload.company_id}`
  }
}
