<template>
  <section :class="['input_section']">
    <label
      v-click-outside="closeComment"
      :for="`input_id_${unique_id}`"
    >
      <p
        :id="`text_input_name_${unique_id}`"
        class="input_name"
      >
        {{ field_name }}
        <span
          v-if="required"
          style="color: red"
          >*</span
        >
      </p>
      <div :class="['text_input', validation_error ? 'input_error' : '', disabled ? 'disabled' : '']">
        <input
          :id="`input_id_${unique_id}`"
          :type="type"
          :value="modelValue"
          :required="required"
          :disabled="disabled"
          :avatar="avatar"
          :placeholder="placeholder"
          @input="$emit('update:modelValue', $event.target.value)"
        />

        <div
          v-if="user.is_analyst"
          class="icon"
        >
          <img
            v-if="comment && !user.is_client"
            :id="`show_comment_${unique_id}`"
            src="@/assets/img/add_comment.svg"
            @click="show_comment = true"
          />
          <div
            v-if="comments.length"
            class="number_of_comments"
          >
            {{ comments ? comments.length : '' }}
          </div>
        </div>
      </div>
      <small
        v-if="hint"
        class="input_hint"
        >{{ hint }}</small
      >

      <div
        v-if="show_comment && comment && !user.is_client"
        :id="`comment_board_input_${unique_id}`"
        class="comment_board"
      >
        <form @submit.prevent="addComment()">
          <div class="add_comment">
            <input
              :id="`enter_${unique_id}`"
              v-model="new_comment.text"
              required
              :placeholder="$t('general.add_comment')"
            />

            <div class="comment_buttons">
              <div
                :id="`add_${unique_id}`"
                :class="['add_button', is_comment_filled ? 'success' : '']"
                @click="addComment"
              >
                {{ $t('general.add') }}
              </div>
            </div>
          </div>
        </form>

        <!-- already created comments -->
        <div class="created_comments">
          <div
            v-for="(c, index) in comments"
            :key="index"
            class="detail_comment"
          >
            <div class="text">
              <div class="comment_title">
                <div class="info">
                  <span class="name">{{ c.creater.username }}</span>
                  <span class="date">{{ c.created.split('T')[0] }}</span>
                </div>

                <div
                  v-if="user.username === c.creater.username"
                  class="action"
                >
                  <button
                    class="delete_button"
                    type="button"
                    @click="delComment(index)"
                  >
                    {{ $t('general.delete') }}
                  </button>
                </div>
              </div>

              <p class="comment_text">
                {{ c.text }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p
        v-if="validation_error"
        class="error_text"
      >
        {{ validation_error }}
      </p>
    </label>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TextInput',

  props: {
    modelValue: {
      type: [String, Number],
    },
    tagId: {
      type: String,
      default: '',
    },
    field_name: String,
    placeholder: String,
    required: Boolean,
    error: String,
    disabled: Boolean,
    avatar: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    hint: {
      type: String,
    },
    // comment params
    // example: information.ogrn. information - form name for get model in backend,
    // ogrn - field name for creating comments
    comment: String,
    comments: {
      type: Array,
      default: () => [],
    }, // list of created comments
    query_param: Number,
  },

  emits: ['uodate_comments', 'update:modelValue'],

  data() {
    return {
      unique_id: '',

      new_comment: {
        text: '',
      },
      show_delete_comment: false,
      current_comment_index: 0,

      show_comment: false,
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  computed: {
    ...mapState(['server', 'auth_headers']),
    ...mapState('user', ['user']),

    validation_error() {
      if (this.error && this.error.length) {
        return this.error
      }
      if (this.type === 'email' && this.modelValue && this.modelValue.length) {
        if (
          this.modelValue.match(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/g,
          )
        ) {
          return ''
        } else {
          return 'Введите корректный email'
        }
      }
      return ''
    },

    is_comment_filled() {
      return !!this.new_comment.text.length
    },

    color() {
      return localStorage.getItem('color')
    },
  },

  methods: {
    closeComment() {
      this.show_comment = false
    },

    addComment() {
      if (this.query_param) {
        this.new_comment.query_param = this.query_param
      } else {
        this.new_comment.query_param = this.$route.params.form_id
      }
      this.new_comment.form_name = this.comment.split('.')[0]
      this.new_comment.field_name = this.comment.split('.')[1]
    },

    delComment(index) {
      this.show_delete_comment = true
      this.current_comment_index = index
    },
  },
}
</script>

<style scoped lang="sass">
@use "@/assets/sass/style.sass"
</style>
