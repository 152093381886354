export default {
  processes: [],
  total_processes: 0,
  default_processes: [],
  inactive_processes: [],
  editable_processes: [],
  process: {},

  process_subjects: [],
  positions: [],
  public_sources: [],
  other_sources: [],
  source_subjects: [],
  paper_docs: [],
  process_systems: [],
  process_system_subjects: [],
  process_third_parties: [],
  process_third_party_subjects: [],
}
